import React from "react"
import PrivacyLayout from "../../components/layouts/PrivacyLayout"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

const Privacy = ({ data }) => {
  const bannerImg = data.backgroundImage

  const backgroundImg = getImage(bannerImg)
  const bgImage = convertToBgImage(backgroundImg)

  return (
    <PrivacyLayout>
      <main id="content" role="main">
        <div className="isolate bg-black border-b-5 border-yellow">
          <div className="container h-quarter-screen lg:h-half-screen relative overflow-hidden">
            <BackgroundImage
              className="w-full h-full relative bg-top bg-center bg-top bg-cover"
              Tag="div"
              {...bgImage}
            >
              <div className="bg-gradient-to-r from-black w-1/2 h-full absolute top-0 left-0 z--2"></div>
              <div className="bg-gradient-to-l from-black w-1/2 h-full absolute top-0 right-0 z--2"></div>
              <div className="grid grid-cols-1 absolute top-0 w-full h-full">
                <div className="flex flex-col h-full">
                  <div className="flex flex-grow w-full items-center">
                    <h1 className="text-center text-white font-bold uppercase lg:text-4xl md:text-3xl sm:text-2xl text-base">
                      THE CURIOSITY TO CHALLENGE CONVENTIONS. THE COURAGE TO
                      FIGHT FOR WHAT IS RIGHT.
                    </h1>
                  </div>
                </div>
              </div>
            </BackgroundImage>
          </div>
        </div>

        {/* <div className="isolate bg-white">
          <div className="container m-auto lg:pt-16 lg:pb-8 md:pt-10 md:pb-5 pt-8 pb-4">
            <div className="grid grid-cols-1">
              <h1 className="lg:text-4xl md:text-3xl text-2xl uppercase mb-0 font-bold wider">
                Privacy Policy
              </h1>
            </div>
          </div>
        </div> */}

        <div className="isolate bg-white">
          <div className="container m-auto lg:pt-8 lg:pb-8 md:pt-10 md:pb-5 pt-8 pb-4">
            <div className="grid grid-cols-1 md:gap-x-40">
              <div>
                <h1 className="lg:text-4xl md:text-3xl text-2xl uppercase mb-0 font-bold wider">
                  Privacy Policy
                </h1>
                <p className="pt-4 pb-4 text-sm">
                  <i>Revised: 03 Sept 2024</i>
                </p>
                <p className="pt-4 pb-4">
                  Tobias is committed to ensuring the fair and appropriate
                  handling of your personal data.
                </p>

                <p className="pt-4 pb-4">
                  This Privacy Policy explains how we handle your personal data,
                  whether you are here to review what we have to offer, one of
                  our clients (aka: a client conducting research), or a
                  prospective research participant.
                </p>

                <p className="pt-4 pb-4">
                  We use the term personal data for simplicity throughout this
                  document to refer to personal information as described in the
                  Australian Privacy Principles (APPs), the framework for
                  Australia's Privacy Act 1988
                </p>

                <p className="pt-4 pb-4">
                  Tobias follows the privacy principles set out in Australia’s
                  Privacy Act 1988. The privacy principles direct our approach
                  to collecting and managing your personal data. Specifically,
                  we:
                  <ul className="list-disc">
                    <li>
                      maintain transparency in how we handle your personal
                      information,
                    </li>
                    <li>
                      limit the collection of personal data to what is essential
                      for our operations,
                    </li>
                    <li>
                      clearly define our data purposes and restrict the use and
                      disclosure of personal data accordingly,
                    </li>
                    <li>
                      take appropriate measures to ensure the security of the
                      personal data in our possession.
                    </li>
                  </ul>
                </p>

                <p className="pt-4 pb-4">
                  Our approach to privacy includes meeting personal data
                  handling requirements and standards set out by the Office of
                  the Australian Information Commissioners, the UK General Data
                  Protection Regulation (GDPR), the NZ Privacy Act 2022 and
                  other relevant data protection laws where our client's or
                  participants are from outside of Australia.
                </p>

                <p className="pt-4 pb-4">
                  Tobias provides design user research and Usability Testing
                  services for our client's and in some cases finds suitable
                  participants for the research studies being conducted for our
                  clients.
                </p>

                <p className="pt-4 pb-4">
                  The services have a number of data collection points:
                  <ul className="list-disc">
                    <li>
                      During design projects, prospective user research
                      participants can register and apply to participate in
                      research opportunities for our clients.
                    </li>
                    <li>
                      There is an application and screening process for each
                      research opportunity. Our team create a series of
                      screening questions to ask prospective participants to
                      meet the project requirements.
                    </li>
                    <li>
                      Based on participant registration and screening question
                      responses, our team selects (i.e., recruit) participants
                      for their research opportunities, schedule research
                      sessions and send messages to participants via the method
                      selected e.g email or SMS.
                    </li>
                    <li>
                      Some clients will ask chosen participants to complete the
                      relevant research studies on-location, however most
                      research is performed online or virtually. We can
                      facilitate our clients' research sessions through
                      video-conferencing tools hosted by MSTeams or Zoom. Video
                      or Audio files created are stored by us for the duration
                      of the research for analysis purposes on our secure data
                      storage platform. De-identified notes or transcripts are
                      made from the session and files.
                    </li>
                    <li>
                      Clients may choose to outsource the completion of
                      recruitment for research studies to trusted third party
                      recruitment partners in which case the personal details of
                      the participants are held by the partner. In this case
                      Tobias will hold the consent form and the research data as
                      above.
                    </li>
                  </ul>
                </p>

                <h2 className="pt-8">
                  Why we collect and process your personal data
                </h2>

                <p className="pt-4 pb-4">
                  Tobias collects and processes your personal data to provide
                  our Design research services. Specifically, we use personal
                  data for the following purposes and under the legal bases set
                  out below. Tobias will not use personal data for a purpose not
                  related to its services or functions.
                </p>

                <h4>Consent</h4>
                <p className="pt-4 pb-4">
                  We don't use any cookies on our site. However we do use Google
                  Analytics to collect anonymised user traffic to understand
                  user behaviours.
                </p>

                <p className="pt-4 pb-4">
                  (Anyone) We receive your response to a marketing offer we made
                  via our website or social media pages
                </p>

                <p className="pt-4 pb-4">
                  (Participants) You can choose whether we collect sensitive
                  information about you, including as part of the screening
                  process for client studies.
                </p>

                <h4>Participant Research</h4>
                <p className="pt-4 pb-4">
                  We screen participants based on the information they provide
                  to us for client studies (using screening questions supplied
                  by the client or our design research team).
                </p>

                <p className="pt-4 pb-4">
                  We verify the accuracy of participant applications and
                  responses to screening questions.
                </p>

                <p className="pt-4 pb-4">
                  We connect our clients with research participants.
                </p>

                <p className="pt-4 pb-4">
                  We set up research study session times.
                </p>

                <p className="pt-4 pb-4">
                  We facilitate communication between our clients and our design
                  research team.
                </p>

                <p className="pt-4 pb-4">
                  We collect and process research study data to meet our agreed
                  services for our clients.
                </p>

                <h2 className="pt-8">
                  What types of personal data do we collect and process?
                </h2>

                <p className="pt-4 pb-4">
                  Personal data is information that identifies you (or could
                  reasonably lead to you being identified). The types of
                  personal data we collect and process depends on our
                  relationship with you. We explain this below:
                </p>

                <p className="pt-4 pb-4">
                  <strong>Analytics data</strong> - We collect and use analytics
                  data collected from your use of the website. We only collect
                  this data from website visitors. All analytics data is
                  anonymised.
                </p>

                <p className="pt-4 pb-4">
                  <strong>Enquiries data</strong> - When you contact us or use
                  social media channels, we use your personal data to
                  communicate with you and answer your enquiries.
                </p>

                <p className="pt-4 pb-4">
                  <strong>Client data</strong> - As our clients are generally
                  companies, personal data required for the service of clients
                  is generally limited to details of a company’s nominated
                  contact person or team of people.
                </p>

                <p className="pt-4 pb-4">
                  <strong>Participant data</strong> - We collect and process
                  participant personal data to screen and schedule participants
                  for research sessions.
                </p>

                <p className="pt-4 pb-4">
                  <strong>Screening Data</strong> - We collect and process
                  participant personal data as part of the screening process for
                  an upcoming research study.
                </p>

                <p className="pt-4 pb-4">
                  <strong>Research study data</strong> - When participants
                  undertake client studies we collect and process this data to
                  provide our agreed services to the client.
                </p>

                <p className="pt-4 pb-4">
                  <strong>Video response data</strong> - When participants
                  provide video responses in design research interviews, we
                  collect and process this data to provide our agreed services
                  to the client.
                </p>

                <p className="pt-4 pb-4">
                  <strong>Contact data</strong> - We collect and process this
                  data to contact clients and participants about upcoming
                  research study opportunities or scheduled studies.
                </p>

                <p className="pt-4 pb-4">
                  <strong>Request for access or correction data</strong> - When
                  you submit a request to access or correct your data, Tobias
                  uses this data to process your request and communicate with
                  you.
                </p>

                <p className="pt-4 pb-4">
                  The following sections set out specific information on how we{" "}
                  <strong>collect and process</strong> personal data that
                  relates to:
                  <ul className="list-disc">
                    <li>General collections of personal data</li>
                    <li>Our clients, and</li>
                    <li>Participants.</li>
                  </ul>
                </p>

                <h2 className="pt-8">
                  When we collect and process your personal data - General
                </h2>

                <p className="pt-4 pb-4">
                  Tobias collects and processes your personal data in a number
                  of instances, which have been set out below. We may share your
                  personal data with our external service providers in some of
                  these instances.
                </p>

                <h2 className="pt-8">Visiting our website</h2>

                <p className="pt-4 pb-4">
                  <strong>Analytics</strong> - When visiting our website Tobias
                  collects data about your navigation of the websites to help us
                  monitor and improve our platform and your user experience.
                </p>

                <p className="pt-4 pb-4">
                  The data collected and processed when you visit our website
                  includes your:
                  <ul className="list-disc">
                    <li>IP Address</li>
                    <li>Browser version</li>
                    <li>Pages visited</li>
                    <li>Time and date of your visit</li>
                    <li>Time spent on pages</li>
                    <li>Approximate location</li>
                  </ul>
                </p>

                <p className="pt-4 pb-4">
                  <strong>Cookies</strong> - We don’t use cookies on our site.
                  However we do use Google Analytics to better understand user
                  behaviours and which content is being interacted with. Google
                  Analytics does not collect any personal information.{" "}
                </p>

                <h2 className="pt-8">When enquiring about our services</h2>

                <p className="pt-4 pb-4">
                  When you contact us we use your personal data to communicate
                  with you and provide you with information about our services.
                  We may collect and use your name, email, phone number and
                  enquiry details.
                </p>

                <h2 className="pt-8">Responding to a marketing offer</h2>
                <p className="pt-4 pb-4">
                  If you choose to respond to a marketing offer that we make on
                  our website or social media pages, we will collect some of
                  your personal data, including your email, approximate
                  location, the landing page ID and the time you visited the
                  page.{" "}
                </p>

                <h2 className="pt-8">
                  When you access or correct your personal data, or submit a
                  privacy complaint
                </h2>

                <p className="pt-4 pb-4">
                  When you submit a request to access or correct your personal
                  data, or submit a privacy complaint, Tobias uses this data to
                  process your request or investigate your concern and to
                  communicate with you.
                </p>

                <p className="pt-4 pb-4">
                  We collect and use your name, contact information and details
                  regarding your request or concern.
                </p>

                <h2 className="pt-8">
                  When we collect and process your personal data
                </h2>

                <p className="pt-4 pb-4">
                  <strong>Clients</strong> - as our clients are generally
                  companies (and/ or corporations, organisations, government
                  agencies), personal data collected from clients is usually
                  limited to details of the nominated contact person or team of
                  people, which we process in a number of circumstances, set out
                  below.
                </p>

                <p className="pt-4 pb-4">
                  We may share personal data with our external service providers
                  in some circumstances.
                </p>

                <h2 className="pt-8">When receiving email notifications</h2>

                <p className="pt-4 pb-4">
                  Tobias collects and processes a client’s contact person’s
                  email address when receiving email notifications.{" "}
                </p>

                <h2 className="pt-8">Facilitating Meeting Sessions</h2>

                <p className="pt-4 pb-4">
                  Tobias uses virtual conferencing tools such as MS Teams for
                  meeting sessions. Tobias may ask for an audio and visual
                  recording of client meeting sessions and the individuals
                  involved in the session.
                </p>

                <h2 className="pt-8">
                  When we collect and process your personal data
                </h2>

                <p className="pt-4 pb-4">
                  <strong>Participants</strong> - Tobias collects and processes
                  participant personal data in a number of instances, which have
                  been set out below.
                </p>

                <p className="pt-4 pb-4">
                  We may share your personal data with our external service
                  providers in some of these instances.{" "}
                </p>

                <h2 className="pt-8">During participant registration</h2>

                <p className="pt-4 pb-4">
                  When you apply to Tobias as a participant for a research
                  study, we collect and use a range of personal data to screen,
                  register for, and schedule research sessions, including:
                  <ul className="list-disc">
                    <li>Email</li>
                    <li>Name</li>
                    <li>Gender</li>
                    <li>Mobile phone number</li>
                  </ul>
                </p>

                <p className="pt-4 pb-4">
                  We only ask for details that are relevant to the research
                  study. Tobias only use research information (including any
                  personal data) for the purpose of conducting research studies,
                  and not for any other purpose.
                </p>

                <h2 className="pt-8">
                  When applying for research study opportunities
                </h2>

                <p className="pt-4 pb-4">
                  When you apply for a research opportunity, we may collect
                  certain information about you in addition to your name,
                  gender, age, as described above, where it relates to the type
                  of participants required for the study (for example, whether
                  you are working in a “profession”).
                </p>

                <p className="pt-4 pb-4">
                  During the application process, you will be asked to answer a
                  number of screening questions. The screening questions are in
                  relation to the particular research studies we are conducting.
                </p>

                <p className="pt-4 pb-4">
                  Tobias uses your answers to assist in narrowing down the
                  participant pool from which to select people to participate in
                  a particular study. Tobias does not use these answers to build
                  a database of information about you; rather, the answers are
                  held only in order to match you with the research
                  opportunities we are seeking participants for.
                </p>

                <p className="pt-4 pb-4">
                  Screening questions may include details about your:
                  <ul className="list-disc">
                    <li>Language</li>
                    <li>Occupation</li>
                  </ul>
                </p>

                <p className="pt-4 pb-4">
                  Where we ask for personal data about you that is considered
                  sensitive information, we will ask for your permission first
                  before we collect and use that information.
                </p>

                <p className="pt-4 pb-4">
                  For quality assurance, it is important that all screening
                  questions are answered by you to the greatest degree of
                  accuracy. As part of the application process, we also collect
                  details regarding your availability to participate in each
                  research opportunity.
                </p>

                <h2 className="pt-8">When participating in studies</h2>

                <p className="pt-4 pb-4">
                  To participate in and complete research studies, you are
                  required to provide details about yourself, including your
                  opinions and preferences. The exact personal data that you are
                  required to provide is dependent on the study being conducted
                  and is determined by Tobias or our client
                </p>

                <p className="pt-4 pb-4">
                  In some study projects you may be recorded, via audio or
                  visual recording (or both), including when you participate in
                  an interview or provide an opinion verbally.{" "}
                  <strong>
                    We will always ask for your permission to use your video
                    response, prior to the recording
                  </strong>
                  .
                </p>

                <h2 className="pt-8">When you get paid</h2>

                <p className="pt-4 pb-4">
                  We pay you when you participate in research studies. We call
                  this an incentive payment. The personal data we collect and
                  process in order to pay you includes:
                  <ul className="list-disc">
                    <li>The payment amount (what you earned - eGift card)</li>
                    <li>Your email address</li>
                    <li>
                      The status of the payment (when you can expect to receive
                      the payment)
                    </li>
                  </ul>
                </p>

                <h2 className="pt-8">When we communicate with you</h2>

                <p className="pt-4 pb-4">
                  We may contact you in a number of circumstances, including
                  when we notify you of potential research opportunities,
                  provide communication from our clients, or remind you of your
                  upcoming study sessions. We collect and process your personal
                  data when we communicate with you, including:
                  <ul className="list-disc">
                    <li>Email</li>
                    <li>Phone number</li>
                    <li>Email or SMS message</li>
                  </ul>
                </p>

                <h2 className="pt-8">How we share your personal data</h2>

                <p className="pt-4 pb-4">
                  Tobias will not sell or share your personal data with
                  advertisers, sponsors, content providers or anyone else –
                  unless:
                  <ul className="list-disc">
                    <li>
                      We have your express permission (e.g. you have expressly
                      chosen to receive marketing materials), or
                    </li>
                    <li>
                      There is a lawful ability or requirement for us to do so
                      (e.g. we receive a court order to produce the information)
                    </li>
                  </ul>
                </p>

                <h2 className="pt-8">How we store your personal data</h2>

                <p className="pt-4 pb-4">
                  Tobias will only store your personal data in electronic
                  format, either on-site or in 'the cloud'. Tobias uses MS Teams
                  and Dropbox for cloud storage, depending on client
                  requirements. Tobias also uses other third-party service
                  providers to store specific types of personal data (such as
                  payment processing data, email and communications data). The
                  personal data stored by third party providers, relates to
                  their functions and services. A full list of our service
                  providers is available on request.
                </p>

                <h2 className="pt-8">How we secure your personal data</h2>

                <p className="pt-4 pb-4">
                  At Tobias, we securely manage and dispose of personal data, by
                  implementing a range of data security practices, including
                  (but not limited to):
                  <ul className="list-disc">
                    <li>
                      Data stored securely on a cloud database with strong
                      automated security features
                    </li>
                    <li>
                      Access to personal data is only provided to staff that
                      require the information to undertake their role
                    </li>
                    <li>Encryption of data and backups</li>
                  </ul>
                </p>

                <p className="pt-4 pb-4">
                  Protection of personal data from unauthorised access and
                  disclosure is a priority for us. Any concerns about the
                  security of personal data held by Tobias should be reported to
                  us without delay, by email: privacy@designtobias.com or phone:
                  +61 (0) 403 290 715.
                </p>

                <h2 className="pt-8">How long we keep your personal data</h2>

                <p className="pt-4 pb-4">
                  We retain your personal data for differing periods, depending
                  on the purpose for which it was collected. We will only keep
                  your personal data for the periods set out below, before your
                  personal data is securely destroyed:
                  <ul className="list-disc">
                    <li>
                      2 years of inactivity on the client account, or where the
                      company is registered as no longer trading
                    </li>
                    <li>
                      Financial transaction information is retained for 7 years
                    </li>
                    <li>
                      Answers to screening questions are retained for 90 days,
                      or until the relevant research opportunity has concluded
                      (whichever is the sooner).{" "}
                    </li>
                    <li>
                      Video or audio recordings and transcriptions, and the
                      personal data contained therein, will be deleted by Tobias
                      within 90 days of the recording. It is noted however that
                      the client may have copies of this data, with any personal
                      data information removed.
                    </li>
                  </ul>
                </p>

                <p className="pt-4 pb-4">
                  In the event you would like your personal data to be
                  completely removed, you can request us to delete your data at
                  any time. Deletion may, however, not occur instantly, as we
                  will require time to delete your data from our backups and
                  from any of our platform service provider locations. If you
                  would like us to delete the personal data we hold about you,
                  please write to us at{" "}
                  <a href="mailto:privacy@designtobias.com">
                    privacy@designtobias.com
                  </a>
                  .
                </p>

                <h2 className="pt-8">
                  Can I access and update my personal data?
                </h2>

                <p className="pt-4 pb-4">
                  If we hold personal data about you, we are happy to tell you
                  what it is. We will not, however, tell someone else what
                  personal data we hold about you (unless you permit us or there
                  is a lawful ability or requirement for us to do so).
                </p>

                <p className="pt-4 pb-4">
                  If you think the personal data we hold about you is incorrect
                  or out of date you can:
                  <ul className="list-disc">
                    <li>Update your details</li>
                    <li>
                      Write to us at{" "}
                      <a href="mailto:privacy@designtobias.com">
                        privacy@designtobias.com
                      </a>{" "}
                      - we are happy to provide access to, and correction of,
                      your personal data. We will need you to provide us with an
                      adequate form of identification, to ensure you are really
                      you.
                    </li>
                  </ul>
                </p>

                <p className="pt-4 pb-4">
                  We will not charge you to access or correct your personal
                  data, and we will get back to your request within 24 hours.
                </p>

                <h2 className="pt-8">Your rights</h2>

                <p className="pt-4 pb-4">
                  Under the UK General Data Protection Regulation, people in the
                  UK have certain rights regarding their personal data held by
                  Tobias, including:
                  <ul className="list-disc">
                    <li>
                      Right of erasure - the right to require Tobias to delete
                      all personal data held about you
                    </li>
                    <li>
                      Right to object - the right to object at any time to
                      certain types of processing of your personal data
                    </li>
                    <li>
                      Right to data portability - the right to receive the
                      personal data Tobias holds about you in an accessible
                      format
                    </li>
                    <li>
                      Right to restrict processing - in certain circumstances
                      you have the right to obtain a restriction on the
                      processing of your personal data
                    </li>
                  </ul>
                </p>

                <p className="pt-4 pb-4">
                  If you are in the UK and have queries about the above, please
                  write to us at{" "}
                  <a href="mailto:privacy@designtobias.com">
                    privacy@designtobias.com
                  </a>
                </p>

                <h2 className="pt-8">Will we update our Privacy Policy?</h2>

                <p className="pt-4 pb-4">
                  We will update this document from time to time to ensure that
                  our personal data handling practices are correctly reflected.
                  This may occur without notice, however we will always post a
                  'last revised' date for your information.
                </p>

                <h2 className="pt-8">Any questions or concerns?</h2>

                <p className="pt-4 pb-4">
                  If you have a question about our Privacy Policy or a concern
                  or complaint about our personal data handling practices,
                  please get in touch with us. You can submit a Privacy
                  Complaints Form (available on request) or contact us at:
                  <br />
                  <strong>Email:</strong>{" "}
                  <a href="mailto:privacy@designtobias.com">
                    privacy@designtobias.com
                  </a>
                  <br />
                  <strong>Phone:</strong>{" "}
                  <a href="tel:+61403290715">+61 (0) 403 290 715</a>
                </p>

                <p className="pt-4 pb-4">
                  We will confirm receipt of your questions or complaint within
                  24 hours. In most cases, we will be able to assist you
                  immediately, however there are some circumstances where we may
                  need additional time to respond to you (such as where
                  additional details are needed to understand your concern or
                  investigate the matter).
                </p>

                <p className="pt-4 pb-4">
                  If you have made a privacy complaint and are not happy with
                  our response to your concerns, you are able to contact the
                  Office of the Australian Information Commissioner via their
                  website –{" "}
                  <a href="https://www.oaic.gov.au" target="_blank">
                    www.oaic.gov.au
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="isolate bg-white">
          <div className="container m-auto lg:pt-8 lg:pb-8 pt-6 pb-4">
            <div className="grid grid-cols-1"></div>
          </div>
        </div>

        <div className="isolate bg-white">
          <div className="container m-auto lg:pt-8 lg:pb-4 md:pt-5 md:pb-2 pt-4 pb-2">
            <div className="grid grid-cols-1"></div>
          </div>
        </div>
      </main>
    </PrivacyLayout>
  )
}

export default Privacy

export const caseStudyListQuery = graphql`
  query contactIndexPageAssets {
    backgroundImage: file(relativePath: { eq: "contact-banner.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
